<script setup>
import XClose from '~/assets/icons/X-close.svg';
// Defining props with the Composition API
defineProps({
    successText: {
        type: String,
        required: true,
    },
    errorText: {
        type: String,
        required: true,
    },
    modelValue: {
        type: [String, Boolean],
        default: null,
    },
});

// console.log(props.successText);

// Using `defineEmits` to define component emits
defineEmits(['update:modelValue']);

// Creating a reactive reference for the component's state, replacing the need for the `data` option
const state = defineModel({ type: [String, Boolean] });

// watch(state, (newValue) => {
//     emit('update:modelValue', newValue);
// });

// Method to handle closing (i.e., resetting the state)
const onClose = () => {
    state.value = null; // Resetting the state
};
</script>

<template>
    <div v-if="successText && state === 'success'" class="mb-4 rounded border border-green-500 px-4 py-2">
        <div class="flex items-center">
            <div>
                <div class="text-sm text-green-800" v-html="successText" />
            </div>
            <div class="ml-auto pl-3">
                <button
                    type="button"
                    class="inline-flex rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                    v-on:click.prevent="onClose"
                >
                    <XClose class="text-green-5-- h-auto w-4 fill-none stroke-current" />
                </button>
            </div>
        </div>
    </div>

    <div v-if="errorText && state === 'error'" class="mb-4 rounded border border-carmin px-4 py-2">
        <div class="flex items-center">
            <div>
                <div class="text-sm text-carmin" v-html="errorText" />
            </div>
            <div class="ml-auto pl-3">
                <button
                    type="button"
                    class="inline-flex rounded-md p-1.5 text-carmin hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-carmin focus:ring-offset-2"
                    v-on:click.prevent="onClose"
                >
                    <XClose class="h-auto w-4 fill-none stroke-current text-carmin" />
                </button>
            </div>
        </div>
    </div>
</template>
